import React from "react";
import Link from "gatsby-link";
import { Layout } from "../layouts/Layout";
import styled from "styled-components";
import { graphql } from "gatsby";
import SEO from "../components/Seo";
import ogImage from "../images/seoImage.png";

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const ActionList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  @media (max-width: 700px) {
    grid-auto-flow: row;
  }
  list-style: none;
  grid-gap: 10px;
  justify-content: start;
  padding: 0;
  a {
    cursor: pointer;
    text-align: center;
    max-width: 400px;
    padding: 25px;
    text-decoration: none;
    color: #222;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 700;
    &:hover {
      opacity: 0.75;
    }
  }
  a.primaryButton {
    background-color: greenyellow;
    border: 2px solid transparent;
  }
  a.secondaryButton {
    border: 2px solid greenyellow;
  }
`;

export const BigComicFont = styled.span`
  font-family: "Bangers";
  font-size: 6rem;
  padding: 1rem 2rem;
  text-shadow: -2px -2px yellow, 2px 2px orange, 4px 4px orange;
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
`;

export const ComicFontRed = styled(BigComicFont)`
  background-image: radial-gradient(
      ellipse farthest-corner,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 35%,
      #ffffff 30%,
      #ffffff 40%,
      rgba(0, 0, 0, 0) 90%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 40%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(
      40deg,
      #bd2d10 0,
      #f4502f 30%,
      #ff6e51 50%,
      #f4502f 70%,
      #bd2d10 100%
    );
`;
export const ComicFontBlue = styled(BigComicFont)`
  background-image: radial-gradient(
      ellipse farthest-corner,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 35%,
      #ffffff 30%,
      #ffffff 40%,
      rgba(0, 0, 0, 0) 90%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 40%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(
      40deg,
      #038ecf 0,
      #51cbf1 30%,
      #7ff9ff 50%,
      #51cbf1 70%,
      #038ecf 100%
    );
`;

const HeroSection = styled.section`
  display: grid;
  grid-gap: 10px;
  place-items: center;
  padding: 3rem 0;
  h2 {
    margin: 0;
  }
  h1 {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    @media (max-width: 700px) {
      grid-template-columns: auto;
      grid-template-rows: 1fr 50px 1fr;
    }
    span {
      display: grid;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default class extends React.Component<IndexPageProps, {}> {
  constructor(props: IndexPageProps, context: any) {
    super(props, context);
  }
  public render() {
    return (
      <Layout>
        <SEO image={ogImage} title={"This vs That: Welcome!"} />
        <HeroSection>
          <h1>
            <ComicFontRed>THIS</ComicFontRed>
            <span>vs.</span>
            <ComicFontBlue>THAT</ComicFontBlue>
          </h1>
          <h2>Choose two things head to head.</h2>
          <h2>Put it up for vote.</h2>
          <h2>Settle it once and for all.</h2>
          <ActionList>
            <Link className="secondaryButton" to="/my-polls">
              My Polls
            </Link>
            <Link className="primaryButton" to="/builder">
              Build Your Own Poll
            </Link>
          </ActionList>
        </HeroSection>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
